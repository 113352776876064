<template>
  <div>
    <NavBar/>
    <Productos/>
    <Fotter></Fotter>     
  </div>
   
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  // Components
  import NavBar from '../components/NavBar.vue';
  import Productos from '../components/Productos.vue';
  import Fotter from '@/components/Fotter.vue';
  
  export default defineComponent({
    name: 'ProductoView',
  
    components: {
      NavBar,
      Productos,
      Fotter
    },
  });
  </script>