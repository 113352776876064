<template> 
  <v-container style="max-width: 1600px; margin: auto; padding: 30px;">
    <h1>Contáctanos</h1>
    <v-row>
      <!-- Columna con mapa y contacto, ocupa todo el ancho en pantallas pequeñas y la mitad en pantallas medianas y grandes -->
      <v-col cols="12" md="6">
        <v-card class="custom-card">
          <v-card-text>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.8543204075454!2d-107.40612602552483!3d24.800441447658287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd0bceebfaec7%3A0x48a8537a1b27401!2sImprenta%20Cordero!5e0!3m2!1ses-419!2smx!4v1729622019330!5m2!1ses-419!2smx"
              width="100%"
              height="300"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <div class="icon-container">
              <v-icon class="mr-2">mdi-map-marker</v-icon>
              <span>{{ contacto.direccion }}</span>
            </div>
            <div class="icon-container">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <span>{{ contacto.telefono }}</span>
            </div>
            <div class="icon-container">
              <v-icon class="mr-2">mdi-whatsapp</v-icon>
              <span>{{  contacto.whatsapp }}</span>
            </div>
            <div class="icon-container">
              <v-icon class="mr-2">mdi-email</v-icon>
              <span>{{ contacto.correo1 }}</span>
            </div>
            <div class="icon-container">
              <v-icon class="mr-2">mdi-email</v-icon>
              <span>{{ contacto.correo2 }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna con formulario, ocupa todo el ancho en pantallas pequeñas y la mitad en pantallas medianas y grandes -->
      <v-col cols="12" md="6">
        <v-card class="custom-card2">
          <v-card-title>Formulario de Contacto</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="sendMessage" ref="contactForm">
              <v-text-field
                label="Nombre"
                v-model="form.name"
                required
              ></v-text-field>

              <v-text-field
                label="Correo Electrónico"
                v-model="form.email"
                required
                type="email"
                :rules="[emailRule]"
              ></v-text-field>

              <v-textarea
                label="Mensaje"
                v-model="form.message"
                required
                rows="8"
              ></v-textarea>
              <div class="button-container">
                <v-btn type="submit" class="custom-button">Enviar</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
        <br />
        <v-alert v-if="message" :type="messageType" dismissible>
            {{ message }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: "ContactoComponet",
  data() {
    return {
      message: '',
      messageType: '',
      form: {
        name: '',
        email: '',
        message: '',
      },
      contacto: {
        direccion: 'Juan José Ríos #449 Pte. Col. Almada Culiacán, Sinaloa. CP.80200',
        telefono: '667 716 1048',
        correo1: 'ventas@imprentacordero.com.mx',
        correo2: 'ventas2@imprentacordero.com.mx',
        whatsapp: '667 150 0727'
      },
      emailRule: (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value) || "Debe ser un correo electrónico válido";
      },
    }
  },
  methods: {
    async sendMessage() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.form.email)) {
        this.messageType = 'error';
        this.message = 'Por favor, ingresa un correo electrónico válido.';
        return;
      }

      try {
        const response = await axios.post(
          'https://imprentacordero.com.mx/api/api/send-email',
          this.form,
          { headers: { 'Content-Type': 'application/json' } }
        );
        console.log(response.data);
        this.messageType = 'success';
        this.message = 'Mensaje enviado correctamente';
        this.form = { name: '', email: '', message: '' };
      } catch (error) {
        if (error.response) {
          console.error('Estado de respuesta de error:', error.response.status);
          console.error('Mensaje de error:', error.response.data);
          this.message = `Error del servidor: ${error.response.statusText}`;
        } else if (error.request) {
          console.error('Error de solicitud sin respuesta:', error.request);
          this.message = 'No se recibió respuesta del servidor.';
        } else {
          console.error('Error', error.message);
          this.message = 'Error al configurar la solicitud.';
        }
        this.messageType = 'error';
      }
    }

  },
}
</script>

<style scoped>
.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}

.contact-form {
  flex: 1;
  margin-right: 20px;
}

.location {
  flex: 1;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.custom-button {
  background-color: #4CAF50;
  color: white !important;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.icon-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.custom-card {
  height: auto;
  width: 100%;
  margin: auto;
  max-width: 600px;
}

.custom-card2 {
  height: auto;
  width: 100%;
  margin: auto;
  max-width: 600px;
}

h1 {
  margin-bottom: 12px;
}
</style>
