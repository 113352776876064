<template>
  <div>
    <NavBar/>
    <Contacto/>
    <Fotter/>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import NavBar from "@/components/NavBar.vue";
import Fotter from "@/components/Fotter.vue";
import Contacto from "@/components/Contacto.vue";

export default defineComponent({
  name: "ContactoView",

  components: {
    NavBar,
    Fotter,
    Contacto
  },
});
</script>

<style>

</style>
