<template>
  <v-container class="main-container">
    <v-app-bar
      app
      color="#fcfcfc"
      dark
      fixed
      elevated="24"
      height="80"
      class="custom-app-bar"
      position: absolute
    >
    <v-app-bar-nav-icon @click="drawer = !drawer" class="menu-icon"></v-app-bar-nav-icon>

      <div style="width: 100px"></div>
      <div class="d-flex align-center">
        
        <router-link to="/">
        <v-img
          alt="CG3"
          :src= CG3
          width="100"
        /></router-link>
      </div>
      <v-toolbar-title><h3></h3></v-toolbar-title>

      <div class="nav-container">
        <nav>
      <ul>
        <li><router-link to="/" class="custom-btn">Nosotros</router-link></li>
        <li><router-link to="/productos" class="custom-btn">Servicios</router-link></li>
        <li><router-link to="/contacto" class="custom-btn">Contáctanos</router-link></li>
        <v-btn
          icon
          color="#05A253"
          href="https://www.facebook.com/ImprentaCorderoMX"
        >
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn icon color="#05A253" href="https://wa.me/6671500727">
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn icon color="#05A253" href="tel:667 716 1048">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </ul>
    </nav>
  </div>
      <div style="width: 100px"></div>
    </v-app-bar>
       <!-- Menú desplegable para pantallas móviles -->
       <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      right
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Menú</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <router-link to="/" class="custom-btn">Nosotros</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/productos" class="custom-btn">Servicios</router-link>
        </v-list-item>
        <v-list-item>
          <router-link to="/contacto" class="custom-btn">Contáctanos</router-link>
        </v-list-item>
        <v-list-item>
          <div class="icon-container">
          <v-btn icon color="#05A253" href="https://www.facebook.com/ImprentaCorderoMX">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn icon color="#05A253" href="https://wa.me/6671500727">
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn icon color="#05A253" href="tel:667 716 1048">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </div>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<style>
.custom-app-bar {
  position: relative;
}

.custom-btn {
  color: #05A253;
  font-size: 18px;
}

.main-container {
  padding: 0px;
  justify-content: center;
  width: 100%;
  max-width: 1800px;
  margin: 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  flex: 1;
}

nav a {
  display: block;
  text-align: center;
  padding: 12px 14px;
  text-decoration: none;
}

.icon-container {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.menu-icon {
  display: none;
}

/* Media query para pantallas móviles */
@media (max-width: 600px) {
  .nav-container {
    display: none; /* Oculta el menú de navegación en pantallas pequeñas */
  }

  .menu-icon {
    display: block; /* Muestra el icono de menú hamburguesa en pantallas pequeñas */
    margin-right: auto; 
  }
}
</style>

<script>
export default {
  name: "NavbarComponent",
  data(){
    return{
      drawer: false,
      CG3:require('@/assets/CG3.webp')
    };
  }
};
</script>
