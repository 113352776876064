<template>
    <v-container style="max-width: 1600px; margin: auto; padding: 30px;">
      <h1>Nosotros</h1>
      <div class="info-container">
          <div class="text-container">
            <div v-for="(info, index) in info" :key="index"> <!-- Cambiado cols a md="3" -->
                <h2>{{ info.title }}</h2>
                  <v-divider
                    :thickness="4"
                    class="border-opacity-75"
                    color="success"
                    style="width: 135px;"
                    ></v-divider>
                    <div class="text-justified">
                    {{ info.description }}
                    </div>
            </div>
          </div>
            <div class="image-container">
              <v-img
                alt="CG3"
                :src= CG3
                width="1800"
              />
            </div>
          </div>
    </v-container>
</template>

<script>

export default {
  name: "NosotrosComponet",

  data() {
    return {
      info: [
        {
          title: "Misión", 
          description:`Nuestra misión es ser una organización integral de artes graficas y transformar las necesidades de comunicación de nuestros clientes en imágenes, a través de diseños innovadores, procesos eficientes y tecnología de vanguardia. Nos comprometemos a satisfacer las más altas exigencias de impresión en cuanto a calidad, rapidez y eficiencia, apoyándonos en el talento excepcional de nuestro equipo humano y en las mejores herramientas tecnológicas disponibles.`
        },
        {
          title: "Visión", 
          description:`Aspiramos a ser una compañía líder presente en el mercado nacional en la rama de las Artes gráficas, mejorando continuamente la interacción con nuestros clientes y provedores para que sea mas eficiente y rápida, a través del uso de la tecnologia. Nos anticipamos a los cambios que exija el mercado. Comprometiendonos a ser una empresa: Altamente productiva y plenamente humana. Inovadora, competitiva y fuertemente orientada a la sastifacción de nuestros clientes y consumidores.`
        },
        {
          title: "Trayectoria", 
          description:`Gaficos CG3, es fundada con nombre comercial imprenta cordero, la cual cuenta con mas de 40 años de experiencia en el ramo de impresión, nos ubicamos en Juan José Ríos #955 pte. Col.Jorge Almada en Culiacán, Sinaloa.`
        },
      ],
      CG3:require('@/assets/fotoMaquina.jpg')
    }
  }
}
</script>

<style scoped>

.main-container {
  padding: 0px; /* Ajusta según la altura de tu barra de navegación */
  justify-content: center; /* Centra el carrusel horizontalmente */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  max-width: 1800px; /* Ajusta el tamaño máximo del contenedor según sea necesario */
  margin: 0; /* Centra el contenedor horizontalmente */
  
}
.text-justified {
  width: auto;
  text-align: justify;
  font-size: 1.15rem;
  line-height: 1.4;
  margin-top: 2px;
  line-height: 1.6;
  padding-block-end: 2%;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Alinea la imagen y el texto en la parte superior */
}

.text-container {
  width: 60%; /* El texto ocupa el 60% del ancho */
  padding-right: 20px;
}

.image-container {
  width: 48%; /* La imagen ocupa el 40% del ancho */
  padding-left: 1%;
  padding-top: 3%;
}

/* Media query para pantallas móviles */
@media (max-width: 600px) {
  .info-container {
    flex-direction: column; /* Coloca los elementos en columna */
    align-items: center; /* Centra el contenido en la pantalla */
  }

  .text-container,
  .image-container {
    width: 100%; /* Ocupa todo el ancho en móviles */
    padding: 0;
  }

  .image-container {
    display: flex;
    justify-content: center;
    padding-top: 1em; /* Espacio arriba de la imagen */
  }

  .text-justified {
    width: auto; /* Ajusta el ancho del texto para pantallas pequeñas */
    font-size: 1rem;
  }
}
</style>
