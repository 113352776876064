<template>
  <v-container style="max-width: 1600px; margin: auto; padding: 30px;">
      <h1>Nuestros servicios</h1>
      <h2>Papeleria Empresarial</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 250px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(papeleriaEmp, index) in papeleriaEmp" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ papeleriaEmp.title }}</v-card-title>
              </v-card-item>
              <v-card-text style="width: 240px; height: 300px;">
                <div class="d-flex justify-center"> <!-- Aquí usamos d-flex y justify-center para centrar -->
                    <v-img
                    :src="papeleriaEmp.img"
                    width="100%" height="150px" class="ma-0"
                    />
                  </div> 
                  <div v-for="(line, idx) in parseDescription(papeleriaEmp.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Terminados</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 140px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(terminados, index) in terminados" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ terminados.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= terminados.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(terminados.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Impresión Digital</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 200px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(impDigital, index) in impDigital" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ impDigital.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= impDigital.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(impDigital.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Impresiones Publicitarias</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 290px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(impPublicitaria, index) in impPublicitaria" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ impPublicitaria.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= impPublicitaria.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(impPublicitaria.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Calendarios</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 145px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(calendarios, index) in calendarios" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ calendarios.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= calendarios.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(calendarios.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Formatos</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 115px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(formatos, index) in formatos" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ formatos.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= formatos.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(formatos.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Material Impreso</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 200px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(matImpreso, index) in matImpreso" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ matImpreso.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= matImpreso.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(matImpreso.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
      <h2>Mas servicios</h2>
      <v-divider
        :thickness="4"
        class="border-opacity-75"
        color="success"
        style="width: 160px;"
      ></v-divider>
      <v-row>
          <v-col class="custom-col" cols="12" md="2" v-for="(masSevicios, index) in masSevicios" :key="index"> <!-- Cambiado cols a md="3" -->
              <v-card 
                class="custom-card"
                elevation="12"
              >
              <v-card-item>
              <v-card-title class="title-container">{{ masSevicios.title }}</v-card-title>
              </v-card-item>
              <v-img
              :src= masSevicios.img
              width="100%" height="150px" class="ma-0"
              />
              <v-card-text>
                  <div v-for="(line, idx) in parseDescription(masSevicios.description)" :key="idx">
                  {{ line }}
                  </div>
              </v-card-text>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProductoComponet",
  data() {
  return {
    papeleriaEmp: [
      { title: "Tarjetas presentación", img: require('@/assets/tarjetasPresentacion.png'), description: "" },
      { title: "Carpetas corporativas", img: require('@/assets/carpetas.png'), description: "" },
      { title: "Hojas membretadas", img: require('@/assets/hojaMembretada.png'), description: "" },
      { title: "Sobres", img: require('@/assets/sobres.png'), description: "" },
      { title: "Gafetes", img: require('@/assets/gafete.png'), description: "" }
  ],
    impPublicitaria: [
      { title: "Pósters/Carteles", img: require('@/assets/poster.png'), description: "" },
      { title: "Volantes", img: require('@/assets/volantes.png'), description: "" },
      { title: "Folletos/Tripticos", img: require('@/assets/triptico.png'), description: "" }
  ],
    formatos: [
      { title: "Notas de venta", img: require('@/assets/notasVenta.png'), description: "" },
      { title: "Bitacoras", img: require('@/assets/bitacora.png'), description: "" },
      { title: "Forma continua", img: require('@/assets/formaContinua.png'), description: "" }
  ],
    terminados: [
      { title: "Empastados", img: require('@/assets/empastado.png'), description: "" },
      { title: "Engargolado", img: require('@/assets/encuardenado.png'), description: "" },
      { title: "Engrapado", img: require('@/assets/engrapado.png'), description: "" },
      { title: "Corte", img: require('@/assets/corte.png'), description: "" },
      { title: "Pegado", img: require('@/assets/pegado.png'), description: "" }
  ],
    impDigital: [
      { title: "Invitaciones", img: require('@/assets/invitaciones.png'), description: "" },
      { title: "Lonas", img: require('@/assets/lonas.png'), description: "" },
      { title: "Etiquetas", img: require('@/assets/etiquetas.png'), description: "" },
      { title: "Boletos", img: require('@/assets/boletos.png'), description: "" }
  ],
    matImpreso: [
      { title: "Exámenes", img: require('@/assets/examenes.png'), description: "" },
      { title: "Revistas", img: require('@/assets/revista.png'), description: "" },
      { title: "Libros", img: require('@/assets/libros.png'), description: "" }
  ],
    calendarios: [
      { title: "De pared", img: require('@/assets/dePared.png'), description: "" },
      { title: "Exfoliador", img: require('@/assets/exfoliador.png'), description: "" },
      { title: "Escritorio", img: require('@/assets/deEscritorio.png'), description: "" }
  ],
    masSevicios: [
      { title: "Sellos", img: require('@/assets/sellos.png'), description: "" },
      { title: "Etiqueta en rollo", img: require('@/assets/etiquetaRollo.png'), description: "" }
  ]
  }
  },
  methods: {
  parseDescription(description) {
    // Divide la descripción en un array usando saltos de línea
    return description.split('\n');
  }
}
}
</script>

<style scoped>
.title-container {
text-align: center;
}
.custom-card {
  width: 240px;
  height: 250px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.custom-col {
  margin-right: 30px;
  margin-left: 20px;
}
</style>