<template>
      <v-container style="max-width: 1600px; margin: auto; padding: 30px;">
      <v-footer app color="#1A1C1D" dark class="custom-footer" position: absolute>
        <v-row class="footer-row" >
          <div class="bloque1">
            <v-col cols="12" sm="6">
              <v-img
                alt="CG3"
                :src= CG3
                width="350px"
              />
            </v-col>
          </div>
          <div class="bloque2">
            <div>
              <p class="titulosFotter">Navegación</p>
              <v-divider
                :thickness="3"
                class="border-opacity-100 dividerfooter"
                color="success"
                style="width: 120px;"
              ></v-divider>
            </div>
            <ul class="navegacion info-fotter">
              <li><router-link to="/" class="navegacion">Nosotros</router-link></li>
              <li><router-link to="/productos" class="navegacion">Servicios</router-link></li>
              <li><router-link to="/contacto" class="navegacion ">Contáctanos</router-link></li>
            </ul>
          </div>
          <div class="bloque3">
          <div>
            <p class="titulosFotter">Visitanos</p>
            <v-divider
                :thickness="3"
                class="border-opacity-100 dividerfooter"
                color="success"
                style="width: 98px;"
              ></v-divider>
          </div>
            <p class="info-fotter">
            Juan José Rios #949 Pte. 
            </p>
            <p>
            Col. Jorge Almada,
            </p>
            <p>
            Culiacán, Mexico, 80200
            </p>
          </div>
          <div class="bloque4">
          <div>
            <p class="titulosFotter">Contactanos</p>
          <v-divider
              :thickness="3"
              class="border-opacity-100 dividerfooter"
              color="success"
              style="width: 130px;"
            ></v-divider>
          </div>
          <v-row class="info-fotter">
            <v-col>
            <v-btn icon  color="#05A253" href="https://www.facebook.com/ImprentaCorderoMX" :ripple="false">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            </v-col>
            <v-col>
            <v-btn icon color="#05A253" href="https://wa.me/6671500727" :ripple="false">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn icon color="#05A253" href="tel:667 716 1048" :ripple="false">
              <v-icon>mdi-phone</v-icon>
            </v-btn>
          </v-col>
          </v-row>
          </div>
        </v-row>
      </v-footer>
      </v-container>
</template>

<script>
export default {
  name: "fotterComponent",
  data(){
    return{
      CG3:require('@/assets/CG3.webp')
    };
  }
};
</script>

<style>
.bloque1{
  position: relative;
  top: 0; 
  left: 250px;
}
.bloque2{
  position: relative;
  top: 0;
  left: 160px;
}
.bloque3{
  position: relative;
  top: 0;
  left: 380px;
}
.bloque4{
  position: relative;
  top: 0;
  left: 570px;
}
.titulosFotter{
    font-size: 20px; /* Cambia el tamaño de la fuente a 15 píxeles */
    color: #eaeaea;
}
.v-footer{
  padding: 20px 0 !important;
}
.custom-footer{
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-btn {
  font-weight: bold; /* Hace que el texto sea negrita */
  font-size: 20px; /* Ajusta el tamaño del texto */
}

.custom-footer {
  background-color: #272A29;
  color: white;
  width: 100%;
}

.navegacion {
  list-style-type: none;
  color:#ffffff;
  text-decoration: none;
}

.info-fotter {
  margin-top: 5px
}
.fotter-row { 
  justify-content: center; /* Centra el carrusel horizontalmente */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  max-width: 1800px;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 600px) {
  .footer-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .dividerfooter {
    width: 120px; /* Ajusta el ancho del divider según lo necesario */
    margin: 4px auto 10px auto; 
  }
  .bloque1,
  .bloque2,
  .bloque3,
  .bloque4 {
    position: static;
    margin-bottom: 20px;
  }

  .bloque2 {
    order: 1;
  }

  .bloque3 {
    order: 2;
  }

  .bloque4 {
    order: 3;
  }

  .social-icons {
    justify-content: center;
  }
}
</style>