<template>
  <div>
    <NavBar/>
    <Nosotros/>
    <Fotter/>
  </div>
 
</template>

<script>
import { defineComponent } from "vue";

// Components
import NavBar from "@/components/NavBar.vue";
import Fotter from "@/components/Fotter.vue";
import Nosotros from "@/components/Nosotros.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    NavBar,
    Fotter,
    Nosotros
  },
});
</script>

<style>

</style>
